<!--
 * @Author: 段海兵
 * @Date: 2022-11-09 16:09:22
 * @LastEditors: 段海兵
 * @Description: filecontent
 * @email: duanhb@bayconnect.com.cn
-->
<template>
  <el-result icon="success" title="成功提示" sub-title="请根据提示进行操作">
    <template slot="extra">
      <el-button type="primary" size="medium" @click="goHome">返回首页</el-button>
    </template>
  </el-result>
</template>

<script>
export default {
  name: 'Result',
  methods: {
    goHome() {
      this.$router.replace({
        name: 'Home'
      })
    }
  }

}
</script>
<style scoped lang="scss">

</style>
